<template>
  <div style="display: grid">
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn small color="primary" @click.stop="save">
        <v-icon left>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </div>
    <div class="d-flex overflow-x-auto pa-2" style="gap: 15px">
      <v-card v-for="t in tramites" :key="t.idTramite" outlined class="pa-2" style="display: grid; grid-template-rows: 0fr 0fr 1fr 0fr 0fr; "  min-width="250">
        <h4 class="pa-1">{{ t.tramite.nombre }}</h4>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item-group v-model="t.idEstadoTramite" color="primary">
            <v-list-item
              v-for="(item, i) in t.tramite.estados"
              :key="i"
              :value="item.idEstadoTramite"
              dense
              :color="item.color"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon" />
              </v-list-item-icon>
                <v-list-item-title v-text="item.estado" />
            </v-list-item>
          </v-list-item-group>
        </v-list>
        
        <v-spacer />

        <v-textarea v-model="t.observaciones" hide-details :rows="3" no-resize auto-grow outlined filled label="Observaciones" />

      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    idInstalacion: Number,
    tramites: Array,
  },
  methods: {
    async save() {
      let tram = this.tramites
        .map(({ idTramite, idEstadoTramite, observaciones }) => ({
          idTramite,
          idEstadoTramite,
          observaciones
        }))
        .filter((t) => t.idEstadoTramite);

      try {
        await axios({
          url: `${process.env.VUE_APP_API_URL}/tramite_instalaciones/${this.idInstalacion}`,
          method: "PATCH",
          data: tram,
        });

        this.$root.$emit("snack", "Se han guardado los cambios");
      } catch {
        this.$root.$emit("snack", "Ha ocurrido un error inesperado", "error");
      }
    },
  },
};
</script>
